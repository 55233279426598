<template>
  <DashboardTemplate>
    <template v-slot:content>
      <div class="w-full my-4 pl-4 pr-4">
        <div class="flex items-center justify-between mb-8">
          <div class="mr-6">
            <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 md:leading-10 truncate">Etsy Fee Calculator</h2>
            <div class="font-medium tracking-tight text-secondary">Calculate the exact fees you'll pay on an Etsy sale</div>
          </div>
          <div class="flex items-center">

            <!-- Visible only on desktop and wide-ish screens -->
            <div class="hidden sm:block">
              <div class="flex sm:flex-col md:flex-row justify-start items-start">
                <Checkbox labelClass="text-md mr-6 sm:mb-1" :checked="offsiteAds" @checked="setOffsiteAds">Offsite Ads</Checkbox>
                <Checkbox labelClass="text-md mr-6 sm:mb-1" :checked="vatRegistered" @checked="setVATRegistered">VAT registered</Checkbox>
                <Checkbox labelClass="text-md" v-if="vatRegistered" :checked="vatableSale" @checked="setVATableSale">VATable sale</Checkbox>
                
              </div>
            </div>

            <!-- Visible only on mobile -->
            <div class="sm:hidden">
              <ButtonMenu iconClass="fas fa-cog" menuClass="px-3 py-3 w-60">
                  <Checkbox labelClass="text-md mb-2" :checked="offsiteAds" @checked="setOffsiteAds">I'm opted-in to Offsite Ads</Checkbox>
                  <Checkbox labelClass="text-md mb-2" :checked="vatRegistered" @checked="setVATRegistered">I'm VAT registered</Checkbox>
                  <Checkbox labelClass="text-md" v-if="vatRegistered" :checked="vatableSale" @checked="setVATableSale">This is a VATable sale</Checkbox>
              </ButtonMenu>
            </div>
          </div>
          
        </div>

        <!-- Price input -->
        <div class="flex md:flex-row flex-col justify-start items-start gap-x-7 mb-7">
          <div class="w-full md:w-6/12">
            <label class="font-medium text-md">Price of the item itself <span v-if="vatRegistered" class="text-orange-400"><small>including VAT</small></span></label>
            <TextInput type="number" class="bg-white text-black" placeholder="e.g 9.99" :required="true" v-model="priceOfItem" />
          </div>
          <div class="w-full md:w-6/12">
            <label class="font-medium text-md">Price of shipping <span v-if="vatRegistered" class="text-orange-400"><small>including VAT</small></span></label>
            <TextInput type="number" class="bg-white text-black" placeholder="e.g 3.99 (leave blank for free shipping)" :required="true" v-model="priceOfShipping" />
          </div>
        </div>

        <!-- Price Overview -->
        <div class="p-4 pr-3 pb-3 bg-white border-green-500 rounded border-l-4 shadow overflow-hidden">
          <div class="font-bold text-md text-secondary uppercase tracking-wider">Your Fee Calculation</div>
          <div class="font-light text-md text-secondary">{{ basedOnText }}</div>
          <div class="font-light text-sm text-secondary italic mb-5">Not correct? Change your settings.</div>
          <div class="grid grid-cols-2 gap-x-7">
            <div class="flex flex-col sm:flex-row justify-between">
              <div class="font-bold text-xl text-secondary text-center md:text-left">Sold organically</div>
              <div class="font-bold text-xl text-secondary text-center md:text-right">{{ totalFeeAmount }}</div>
            </div>
            <div class="flex flex-col sm:flex-row justify-between">
              <div class="font-bold text-xl text-secondary text-center md:text-left">Sold via Offsite Ads</div>                
              <div class="font-bold text-xl text-secondary text-center md:text-right">{{ offsiteAdAmount }}</div>
            </div>
          </div>
        </div>

      </div>

    </template>
  </DashboardTemplate>
</template>

<script>
export default {
  name: 'FeeCalculator',
  data: function () {
    return {
      offsiteAds: false, 
      vatRegistered: false, 
      vatableSale: false, 
      priceOfItem: '',
      priceOfShipping: '',
    }
  },
  methods: { 
    'setOffsiteAds': function(value) { 
      this.offsiteAds = value;
      localStorage.offsiteAds = value;
    },
    'setVATRegistered': function(value) { 
      this.vatRegistered = value;
      localStorage.vatRegistered = value;
    },
    'setVATableSale': function(value) { 
      this.vatableSale = value;
      localStorage.vatableSale = value;
    },
    'checkVATRegistered': function() { 
      alert('vatRegistered: ' + this.vatRegistered);
    }
  }, 
  created() {
    if (localStorage.getItem('offsiteAds') !== null) {
      this.offsiteAds = (localStorage.offsiteAds === 'true');
    }
    if (localStorage.getItem('vatRegistered') !== null) {
      this.vatRegistered = (localStorage.vatRegistered === 'true');
    }
    if (localStorage.getItem('vatableSale') !== null) {
      this.vatableSale = (localStorage.vatableSale === 'true');
    }    
  },
  computed: {
    basedOnText: function () {
      var text = 'Based on ';
      if ( !this.vatRegistered ) { 
        text = text + ' you not being VAT registered.';
      } else { 
        text = text + ' being VAT registered';
        if ( this.vatableSale ) { 
          text = text + ', with this being a VATable sale.'
        } else { 
          text = text + ', with this being a zero-rated sale.'
        }
      }
      return text;
    },
    listingFeeAmount: function() {       
      if ( this.priceOfItem.length > 0 )
      {
        var amount = parseFloat( '0.16' );
        if ( !this.vatRegistered ) { 
          amount = amount * 1.2;
        }
        return amount.toFixed(2);
      } else { 
        return 0.00;
      }
    },
    transactionFeeAmount: function() { 
      if ( this.priceOfItem.length > 0 && parseFloat(this.priceOfItem) > 0 ) { 
        var amount = parseFloat( (this.priceOfItem / 100) * 5 );
        if ( !this.vatRegistered ) { 
          amount = amount * 1.2;
        }
        return amount.toFixed(2);
      } else { 
        return 0.00;
      }
    },
    shippingFeeAmount: function() { 
      if ( this.priceOfShipping.length > 0 && parseFloat(this.priceOfShipping) > 0 ) { 
        var amount = parseFloat( (this.priceOfShipping / 100) * 5 );
        if ( !this.vatRegistered ) { 
          amount = amount * 1.2;
        }
        return amount.toFixed(2);
      } else { 
        return 0.00;
      }
    },
    paymentProcessingFeeAmount: function() { 
      if ( this.priceOfItem.length > 0 )
      {
        var transactionAmount = parseFloat(this.priceOfItem);
        if ( this.priceOfShipping.length > 0 ) { 
          transactionAmount = transactionAmount + parseFloat(this.priceOfShipping);
        }
        transactionAmount = transactionAmount.toFixed(2);
        var amount = parseFloat( ((transactionAmount / 100) * 4) + 0.20 );    
        if ( !this.vatRegistered ) { 
            amount = amount * 1.2;
        }
        return amount.toFixed(2);
      } else { 
        return 0.00;
      }
    },
    totalFeeAmount: function() { 
      var listingFee = parseFloat(this.listingFeeAmount);
      var transactionFee = parseFloat(this.transactionFeeAmount);
      var shippingFee = parseFloat(this.shippingFeeAmount);
      var paymentProcessingFee = parseFloat(this.paymentProcessingFeeAmount);
      var finalAmount = (listingFee + transactionFee + shippingFee + paymentProcessingFee).toFixed(2);
      return window.currentUser.currency.symbol + finalAmount;
      //return '£1.00';
    },
    offsiteAdAmount: function() { 
      if ( !this.offsiteAds ) { 
        return 'N/A';
      } else { 
        return '£2.00';
      }
    }
  }
}
</script>
